import {subscribe} from 'api/blog';
import {InputField} from 'components/FinalForm';
import {Image} from 'components/Image';
import {useClient} from 'lib/client';
import {validateEmail} from 'lib/finalForm/validators';
import {buildMessage} from 'lib/intl';
import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {useIntl, defineMessages} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

type Props = {
  onEnter?: () => void;
  withImage?: boolean;
};

const messages = defineMessages({
  description: {
    defaultMessage: 'Sign-up to our newsletters',
    description: 'Title for subscribe section on category blog page',
  },
  label: {
    defaultMessage: 'Email',
    description: 'Label for email input',
  },
  title: {
    defaultMessage: 'Exclusive content and latest data',
    description: 'Title for subscribe section on category blog page',
  },
});

type FormShape = {email: string};

export function BlogSubscribeCard({onEnter, withImage}: Props): React.ReactElement {
  const client = useClient();
  const intl = useIntl();
  const onSubmit = useCallback(
    async (data: FormShape) => {
      const trimmedEmail = data.email.toString().trim();
      if (!trimmedEmail) {
        return;
      }
      await subscribe(client, {email: trimmedEmail});
    },
    [client],
  );
  return (
    <div className={styles.root}>
      {withImage && (
        <div className={styles.imageContainer}>
          <Image className={styles.image} loading='lazy' src='/illustrations/blog/starter_pack.png' />
        </div>
      )}
      <div className={styles.text}>{buildMessage(intl, messages.title)}</div>
      <div className={styles.description}>{buildMessage(intl, messages.description)}</div>
      <Form<FormShape> onSubmit={onSubmit}>
        {({handleSubmit, submitting, submitSucceeded}) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.input}>
              <InputField
                fullSize
                label={buildMessage(intl, messages.label)}
                name='email'
                size='large'
                type='email'
                validate={validateEmail}
              />
            </div>
            <Button
              color='black'
              loading={submitting}
              onClick={onEnter}
              shape='classic'
              showCheck={submitSucceeded}
              size='large'
              type='submit'
            >
              →
            </Button>
          </form>
        )}
      </Form>
    </div>
  );
}
