import {Language} from 'lib/language/types';
import {reverseUrl} from 'lib/router';
import {JOOM_PRO_ANALYTICS_URL} from 'lib/router/constants';
import {NavigationItem} from './types';

const toNavigationItems = (arr: string[]): NavigationItem[] => {
  return arr.map((label) => ({href: reverseUrl.blogPostsByTag({tag: label}), label, prefix: '#'}));
};

export const getCategories = (language: Language): NavigationItem[] =>
  toNavigationItems(
    language === Language.PT_BR
      ? ['Importação', 'E-commerce', 'Análises', 'Notícias', 'Sobre-nós']
      : ['Import', 'E-commerce', 'Analytics', 'News', 'About us'],
  );

export const getMaterials = (language: Language): NavigationItem[] =>
  toNavigationItems(
    language === Language.PT_BR ? ['Webinars-e-eventos', 'Ebooks', 'Cases'] : ['Events', 'Ebook', 'Cases'],
  );

export const getLinks = (): NavigationItem[] => [
  {href: reverseUrl.main(), label: 'JoomPro', prefix: '→'},
  {href: JOOM_PRO_ANALYTICS_URL.root, label: 'JoomPro Analytics', prefix: '→'},
];
