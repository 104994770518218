import {ProfileButton, Profile} from 'components/ProfileButton';
import Burger from 'icons/burger.svg';
import Close from 'icons/close.svg';
import React, {useMemo} from 'react';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

export type ActionsProps = {
  isMenuOpened: boolean;
  onBurgerClick?(): void;
  profile?: Profile;
};

export function Actions({profile, isMenuOpened, onBurgerClick}: ActionsProps): React.ReactElement {
  const profileButton = useMemo(
    () => profile && <ProfileButton onSignIn={profile.onSignIn} onSignOut={profile.onSignOut} user={profile.user} />,
    [profile],
  );
  return (
    <>
      <div className={styles.desktop}>{profileButton}</div>
      <div className={styles.tablet}>
        {profileButton}
        <Button
          color='outline-black'
          iconLeft={isMenuOpened ? <Close className={styles.icon} /> : <Burger className={styles.icon} />}
          onClick={onBurgerClick}
          shape='circle'
          size='medium'
          type='button'
        />
      </div>
    </>
  );
}
