import cn from 'classnames';
import {Reducer} from 'components/Layout/Reducer';
import {PromoLink} from 'components/PromoLink';
import {useResize} from 'lib/hooks';
import {buildMessage} from 'lib/intl';
import {useLanguage} from 'lib/language/hooks';
import React, {useState, useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {RemoveScroll} from 'react-remove-scroll';
import {Actions, ActionsProps} from './Actions';
import {getCategories, getMaterials, getLinks} from './content';
import styles from './index.module.scss';
import {LogoLink} from './LogoLink';
import {MenuMobile} from './MenuMobile';
import {ToggleMenuButton} from './ToggleMenuButton';

type Props = {
  inverse?: boolean;
  onConnect?(sectionId: string): void;
  profile?: ActionsProps['profile'];
  withBack?: boolean;
};

const messages = defineMessages({
  categoriesButton: {
    defaultMessage: 'Categories',
    description: 'Button in navigation on any blog page',
  },
  materialsButton: {
    defaultMessage: 'Materials',
    description: 'Button in navigation on any blog page',
  },
});

export function BlogNavigation({profile}: Props): React.ReactElement {
  const intl = useIntl();
  const language = useLanguage();
  const categories = getCategories(language);
  const materials = getMaterials(language);
  const links = getLinks();
  const [opened, setOpened] = useState(false);

  const toggle = useCallback(() => {
    setOpened(!opened);
  }, [opened, setOpened]);

  const close = useCallback(() => {
    if (opened) {
      setOpened(false);
    }
  }, [opened, setOpened]);

  useResize(close);

  return (
    <div
      className={cn(styles.container, {
        [styles.navigationFullscreen]: opened,
      })}
    >
      <Reducer>
        <div className={styles.content}>
          <LogoLink />
          <nav className={styles.nav}>
            <div className={styles.navGroup}>
              <ToggleMenuButton items={categories} label={buildMessage(intl, messages.categoriesButton)} />
              <ToggleMenuButton items={materials} label={buildMessage(intl, messages.materialsButton)} />
            </div>

            <div className={styles.navGroup}>
              {links.map((item) => (
                <PromoLink href={item.href} iconLeft={item.prefix} key={item.href} theme='default'>
                  {item.label}
                </PromoLink>
              ))}
            </div>
          </nav>
          <Actions isMenuOpened={opened} onBurgerClick={toggle} profile={profile} />
        </div>
        <div
          className={cn(styles.mobileMenu, {
            [styles.mobileMenuOpened]: opened,
          })}
        >
          <RemoveScroll enabled={opened}>
            <MenuMobile categories={categories} links={links} materials={materials} />
          </RemoveScroll>
        </div>
      </Reducer>
    </div>
  );
}
