import {BlogSubscribeCard} from 'components/BlogSubscribeCard';
import {ButtonToggle} from 'components/ButtonToggle';
import {PromoLink} from 'components/PromoLink';
import {buildMessage} from 'lib/intl';
import React, {useState, useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {NavigationItem} from '../types';
import styles from './index.module.scss';

type Props = {
  categories: NavigationItem[];
  links: NavigationItem[];
  materials: NavigationItem[];
};

const messages = defineMessages({
  categoriesButton: {
    defaultMessage: 'Categories',
    description: 'Button in navigation on any blog page',
  },
  materialsButton: {
    defaultMessage: 'Materials',
    description: 'Button in navigation on any blog page',
  },
  projects: {
    defaultMessage: 'Our projects',
    description: 'Title in mobile navigation on any blog page',
  },
});

export const MenuMobile = ({categories, materials, links}: Props): React.ReactElement => {
  const intl = useIntl();
  const [openedMenu, openMenu] = useState<'materials' | 'categories' | undefined>(undefined);

  const onCategoriesClick = useCallback(
    () => openMenu(openedMenu === 'categories' ? undefined : 'categories'),
    [openMenu, openedMenu],
  );

  const onMaterialsClick = useCallback(
    () => openMenu(openedMenu === 'materials' ? undefined : 'materials'),
    [openMenu, openedMenu],
  );

  return (
    <div className={styles.root}>
      <nav className={styles.nav}>
        <ButtonToggle expanded={openedMenu === 'categories'} onClick={onCategoriesClick}>
          <span className={styles.text}>{buildMessage(intl, messages.categoriesButton)}</span>
        </ButtonToggle>
        {openedMenu === 'categories' && (
          <section className={styles.list}>
            {categories.map((item) => (
              <PromoLink href={item.href} iconLeft={item.prefix} key={item.href} theme='default' underlined>
                {item.label}
              </PromoLink>
            ))}
          </section>
        )}
        <ButtonToggle expanded={openedMenu === 'materials'} onClick={onMaterialsClick}>
          <span className={styles.text}>{buildMessage(intl, messages.materialsButton)}</span>
        </ButtonToggle>
        {openedMenu === 'materials' && (
          <section className={styles.list}>
            {materials.map((item) => (
              <PromoLink href={item.href} iconLeft={item.prefix} key={item.href} theme='default' underlined>
                {item.label}
              </PromoLink>
            ))}
          </section>
        )}
        <span className={styles.text}>{buildMessage(intl, messages.projects)}</span>
        <section className={styles.list}>
          {links.map((item) => (
            <PromoLink href={item.href} iconLeft={item.prefix} key={item.href} theme='default' underlined>
              {item.label}
            </PromoLink>
          ))}
        </section>
      </nav>
      <div className={styles.subscribe}>
        <BlogSubscribeCard />
      </div>
    </div>
  );
};
