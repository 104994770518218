import {Reducer} from 'components/Layout/Reducer';
import React from 'react';
import {Card} from './Card';
import {Header} from './Header';
import styles from './index.module.scss';

type BlogArticlesLayoutProps = {children: React.ReactNode; header?: React.ReactNode};

export function BlogArticlesLayout({children, header}: BlogArticlesLayoutProps): React.ReactElement {
  return (
    <Reducer>
      {header}
      <div className={styles.layout}>{children}</div>
    </Reducer>
  );
}

BlogArticlesLayout.Card = Card;
BlogArticlesLayout.Header = Header;
