import {BlogHeading} from 'components/BlogHeading';
import {Typography} from 'components/Main/Typography';
import {PromoLink} from 'components/PromoLink';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl, defineMessages} from 'react-intl';
import styles from './index.module.scss';

export type SectionHeaderProps = {
  href?: string;
  title: React.ReactNode;
};

const messages = defineMessages({
  newestPosts: {
    defaultMessage: 'All articles',
    description: 'Link caption on blog articles page',
  },
});

export function Header({title, href}: SectionHeaderProps): React.ReactElement {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <BlogHeading>{title}</BlogHeading>
      {href && (
        <Typography alternative kind='promo' size='m' variant='subtitle'>
          <PromoLink href={href} iconLeft='→' theme='default' underlined>
            {buildMessage(intl, messages.newestPosts)}
          </PromoLink>
        </Typography>
      )}
    </div>
  );
}
