import React from 'react';
import styles from './index.module.scss';
import Stub from './stub.svg';

export const ImageLoader = (): React.ReactElement => {
  return (
    <div className={styles.container}>
      <Stub className={styles.icon} />
    </div>
  );
};
