import cn from 'classnames';
import {Badge} from 'components/Badge';
import {Image} from 'components/Image';
import {useImageLoaded} from 'lib/hooks';
import {getSrcByWidth} from 'lib/image';
import {ImageBundle} from 'lib/image/types';
import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl, defineMessages} from 'react-intl';
import styles from './index.module.scss';
import {ImageLoader} from './Loader';

type ImageProps = {
  alt: string | undefined;
  image: ImageBundle;
  imageClassName?: string;
  showNewBadge?: boolean;
  width: number;
};

const messages = defineMessages({
  new: {
    defaultMessage: 'New post',
    description: 'Badge caption for new blog post',
  },
});

export const BlogImage = ({
  image,
  width,
  alt,
  imageClassName,
  showNewBadge = false,
}: ImageProps): React.ReactElement => {
  const src = getSrcByWidth(image, width);
  const intl = useIntl();
  const {ref, loaded, onLoad} = useImageLoaded();
  return (
    <div className={styles.root}>
      <ImageLoader />
      <Image
        alt={alt}
        className={cn(styles.image, imageClassName, {
          [styles.loaded]: loaded,
        })}
        loading='lazy'
        onLoad={onLoad}
        ref={ref}
        src={src}
      />
      {showNewBadge && <Badge align='left-top'>♥ {buildMessage(intl, messages.new)}</Badge>}
    </div>
  );
};
