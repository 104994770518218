import React from 'react';
import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
};

export function BlogHeading({children}: Props): React.ReactElement {
  return (
    <h2 className={styles.title}>
      <span className={styles.prefix}>#</span> {children}
    </h2>
  );
}
