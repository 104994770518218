import {buildMessage} from 'lib/intl';
import React from 'react';
import {useIntl, defineMessages, FormatDateOptions} from 'react-intl';
import styles from './index.module.scss';

const FORMAT_DATE_OPTIONS: FormatDateOptions = {
  day: 'numeric',
  month: 'short',
};

type Props = {
  date: Date;
  readingTimeMin: number;
};

const messages = defineMessages({
  readingTime: {
    defaultMessage: '{readingTimeMin} min read',
    description: "Caption with article's reading time",
  },
});

export const BlogArticleInfo = ({date, readingTimeMin}: Props): React.ReactElement => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      {buildMessage(intl, messages.readingTime, {readingTimeMin})}
      <div className={styles.delimeter} />
      {intl.formatDate(date, FORMAT_DATE_OPTIONS)}
    </div>
  );
};
