import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {ButtonToggle} from 'components/ButtonToggle';
import React, {useRef} from 'react';
import {Menu, MenuItem} from 'uikit/Menu/index';
import {NavigationItem} from '../types';
import styles from './index.module.scss';

type Props = {
  items: NavigationItem[];
  label: React.ReactNode;
};

export const ToggleMenuButton = ({label, items}: Props): React.ReactElement => {
  const buttonRef = useRef(null);
  const popupState = usePopupState();
  return (
    <>
      <ButtonToggle expanded={popupState.isOpen} onClick={popupState.open} ref={buttonRef}>
        <span className={styles.button}>{label}</span>
      </ButtonToggle>
      {popupState.isOpen && (
        <Menu onClose={popupState.close} targetRef={buttonRef}>
          {items.map((item) => (
            <MenuItem href={item.href} key={item.href}>
              <span className={styles.prefix}>{item.prefix}</span> {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
