import {subscribe} from 'api/blog';
import cn from 'classnames';
import {InputField} from 'components/FinalForm';
import {Image} from 'components/Image';
import {Reducer} from 'components/Layout/Reducer';
import {useClient} from 'lib/client';
import {validateEmail} from 'lib/finalForm/validators';
import {buildMessage} from 'lib/intl';
import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {useIntl, defineMessages} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';

type Props = {
  theme: 'light' | 'dark';
};

const messages = defineMessages({
  button: {
    defaultMessage: 'Subscribe',
    description: 'Button for subscribe section on main blog page',
  },
  description: {
    defaultMessage: 'Subscribe to get latest news and exclusive content',
    description: 'Title for subscribe section on main blog page',
  },
  label: {
    defaultMessage: 'Email',
    description: 'Label for email input',
  },
  title: {
    defaultMessage: 'Join the newsletter!',
    description: 'Title for subscribe section on main blog page',
  },
});

type FormShape = {email: string};

export function BlogSectionSubscribe({theme}: Props): React.ReactElement {
  const intl = useIntl();
  const client = useClient();
  const onSubmit = useCallback(
    async (data: FormShape) => {
      const trimmedEmail = data.email.toString().trim();
      if (!trimmedEmail) {
        return;
      }
      await subscribe(client, {email: trimmedEmail});
    },
    [client],
  );
  return (
    <div
      className={cn(styles.container, {
        [styles.dark]: theme === 'dark',
        [styles.light]: theme === 'light',
      })}
    >
      <Reducer>
        <div className={styles.grid}>
          <div className={styles.imageContainer}>
            <Image className={styles.image} loading='lazy' src='/illustrations/blog/starter_pack.png' />
          </div>
          <div className={styles.content}>
            <div className={styles.text}>{buildMessage(intl, messages.title)}</div>
            <div className={styles.description}>{buildMessage(intl, messages.description)}</div>
            <Form<FormShape> onSubmit={onSubmit}>
              {({handleSubmit, submitting, submitSucceeded}) => (
                <form className={styles.form} onSubmit={handleSubmit}>
                  <InputField
                    dark
                    label={buildMessage(intl, messages.label)}
                    name='email'
                    size='large'
                    type='email'
                    validate={validateEmail}
                  />
                  <Button
                    color='accent'
                    loading={submitting}
                    shape='classic'
                    showCheck={submitSucceeded}
                    size='large'
                    type='submit'
                  >
                    {buildMessage(intl, messages.button)}
                  </Button>
                </form>
              )}
            </Form>
          </div>
        </div>
      </Reducer>
    </div>
  );
}
