import {Link} from 'components/Link';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {reverseUrl} from 'lib/router';
import React, {useCallback} from 'react';
import {Logo} from './Logo';

export function LogoLink(): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handleMainClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'logo',
        source: appEnv.currentPageName,
      },
      type: 'mainClick',
    });
  }, [analytics, appEnv]);

  return (
    <Link href={reverseUrl.blog()} onClick={handleMainClick}>
      <Logo />
    </Link>
  );
}
