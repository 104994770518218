import cn from 'classnames';
import {BlogArticleInfo} from 'components/BlogArticleInfo';
import {BlogImage} from 'components/BlogImage';
import {Link} from 'components/Link';
import {BlogArticleLite} from 'lib/blogArticle/types';
import {reverseUrl} from 'lib/router';
import React from 'react';
import styles from './index.module.scss';

type Props = Pick<BlogArticleLite, 'slug' | 'imageMain' | 'date' | 'title' | 'readTimeMin' | 'description'> & {
  isNew?: boolean;
};

export const Card = ({slug, imageMain, date, title, readTimeMin, description, isNew}: Props): React.ReactElement => {
  return (
    <article className={cn(styles.root, {[styles.hero]: isNew})}>
      <Link className={styles.link} href={reverseUrl.blogPost({slug})}>
        <BlogImage
          alt={imageMain.alt}
          image={imageMain.image.bundle}
          imageClassName={styles.image}
          showNewBadge={isNew}
          width={500}
        />
        {isNew ? <span className={styles.titleHero}>{title}</span> : <span className={styles.title}>{title}</span>}
        <div className={styles.description}>{description}</div>
        <div className={styles.footer}>
          <BlogArticleInfo date={date} readingTimeMin={readTimeMin} />
        </div>
      </Link>
    </article>
  );
};
